<template>
  <v-dialog
    v-model="imgUploadDialog"
    :key="item._id"
    max-width="900"
    scrollable
  >
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" icon color="grey lighten-1">
        <v-icon color="blue">mdi-image-plus</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="px-2 px-sm-6">
        <v-row class="pb-3" no-gutters>
          <v-col
            cols="8"
            lg="10"
            class="d-flex flex-column justify-center align-start py-0"
          >
            <h3 class="title text-start text-break">
              Upload images for:
              <span class="text-left capitalize">'{{ item.city }}'</span>
            </h3>
          </v-col>
          <v-col
            cols="4"
            lg="2"
            class="d-flex flex-column justify-center align-end py-0"
          >
            <v-btn icon color="grey lighten-1" @click="imgUploadDialog = false">
              <v-icon class="dialog-close-icon">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row
          class="mx-0"
          align="center"
          justify="center"
          justify-md="start"
          no-gutters
        >
          <v-col cols="12" class="px-0 py-0">
            <v-row class="mx-0" no-gutters>
              <span class="text-left secondary--text subtitle-1">
                <b>Square Image</b>
              </span>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            class="d-flex flex-column align-start justify-start px-0 mb-8"
          >
            <v-row class="mx-0 w-100" no-gutters>
              <ImageCropper
                :width="250"
                :height="250"
                :key="key"
                @getBase64="getBase64LogoFn"
                :initialImage="img[0]"
              />
            </v-row>
          </v-col>
          <v-col
            cols="12"
            class="d-flex flex-column align-start justify-start px-0 py-0"
          >
            <v-row class="mx-0" no-gutters>
              <span class="text-left secondary--text subtitle-1">
                <b>Cover Image</b>
              </span>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            class="d-flex flex-column align-start justify-start px-0"
          >
            <v-row class="mx-0 w-100" no-gutters>
              <ImageCropper
                :width="528"
                :height="297"
                @getBase64="getBase64CoverFn"
                :key="key"
                :initialImage="coverImg[0]"
              />
            </v-row>
            <!-- <img :src="coverImg[0]" alt srcset /> -->
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="px-6">
        <v-row
          class="
            d-flex
            flex-column-reverse flex-sm-row
            align-center
            justify-space-between
            mx-0
          "
          no-gutters
        >
          <v-col
            cols="12"
            sm="4"
            class="d-flex flex-column align-center align-sm-start px-0"
          >
            <v-btn
              class="px-8 mx-0"
              color="secondary"
              @click="imgUploadDialog = false"
              tile
              outlined
              depressed
              bottom
              >Cancel</v-btn
            >
          </v-col>
          <v-col
            cols="12"
            sm="4"
            class="d-flex flex-column align-center align-sm-end px-0"
          >
            <v-btn
              class="px-8 mx-0"
              @click="imgUploadFn"
              :loading="isLoading"
              color="secondary"
              tile
              depressed
              bottom
              right
              >Submit</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import cityApicalls from "../../../../services/city.service";
import Upload from "../../../../services/imageUpload.service";

import ImageCropper from "./ImageCropper";
export default {
  name: "ImageUpload",
  components: {
    ImageCropper,
  },
  props: {
    item: Object,
  },
  data() {
    return {
      imgUploadDialog: false,
      logoBase64: null,
      isLoading: false,
      coverBase64: null,
      coverImg: false,
      img: false,
      key: Number(new Date()),
    };
  },
  watch: {
    imgUploadDialog(val) {
      if (val) {
        this.initFn();
      }
    },
  },
  methods: {
    async initFn() {
      let payload = {
        googleId: this.item.googlePlaceId,
      };
      try {
        let { data } = await cityApicalls.GetCityProfileById(payload);
        if (data.message == "success") {
          this.coverImg = data.content.city.coverImg;
          this.img = data.content.city.img;
          this.key = Number(new Date());
        }
      } catch (error) {
        let payload2 = {
          snackBarText: error,
          snackBarColor: "error",
        };
        this.$store.dispatch("notify/setSnackbarMessage", payload2);
      }
    },
    getBase64LogoFn(val) {
      this.logoBase64 = val;
    },
    getBase64CoverFn(val) {
      this.coverBase64 = val;
    },
    async imgUploadFn() {
      this.isLoading = true;
      let payloadMain = {
        _id: this.item._id,
        imgUrl: null,
        imgCoverUrl: null,
      };
      if (this.logoBase64) {
        let payload = {
          Path: `cities/${this.item.city}/square${Number(new Date())}.jpg`,
          Base64: this.logoBase64,
        };
        await Upload.imageUpload(payload).then((res) => {
          payloadMain.imgUrl = res.data.content;
        });
      }
      if (this.coverBase64) {
        let payload = {
          Path: `cities/${this.item.city}/cover${Number(new Date())}.jpg`,
          Base64: this.coverBase64,
        };
        await Upload.imageUpload(payload).then((res) => {
          payloadMain.imgCoverUrl = res.data.content;
        });
      }

      cityApicalls.SetCityAdminImage(payloadMain).then((res) => {
        if (res.data.success) {
          this.imgUploadDialog = false;
          let payload2 = {
            snackBarText: `City Details Updated`,
            snackBarColor: "success",
          };
          this.$store.dispatch("notify/setSnackbarMessage", payload2);
          this.$emit("initFN");
        }
      });

      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
.capitalize {
  text-transform: capitalize;
}
</style>