export default [
  {
    name: "Sustainability",
    subtopics: [
      "Circular Economy",
      "Renewable Energy",
      "Environmental Services",
      "Wind Power",
      "Waste Management",
      "Green Building",
      "Cleantech",
      "Environmental Technology",
      "Sustainable Construction",
      "Green Initiatives",
      "Eco-friendly",
      "Sustainable Development",
      "Eco-Tourism",
      "Environmental Conservation",
      "Corporate Social Responsibility (CSR)",
      "Zero Waste",
      "Sustainability Reporting",
    ]
  }, {
    name: "Biology",
    subtopics: [
      "Agriculture",
      "Zoology",
      "Forestry",
      "Horticulture",
      "Botany",
      "Molecular",
      "Conservation",
      "Crops",
      "Microbiology",
      "Soil Science",
      "Biotechnology",
      "Marine",
      "Animal Produce",
      "Fruit Growing",
      "Genetics",
      "Plant Breeding",
      "Biochemistry",
      "Stockbreeding",
      "Fisheries",
      "Animal",
      "Life Sciences",
      "Pharmaceuticals",
      "Medical Research",
      "Bioinformatics",
      "Genetic  Engineering",
      "Biomedical Technology",
      "Healthcare"
    ]
  },
  {
    name: "Construction",
    subtopics: [
      "Engineering",
      "Material Science",
      "Architecture",
      "Industry",
      "Building",
      "Mechanical",
      "Infrastructure",
      "Real Estate",
      "Building Materials",
      "Urban Development",
      "Construction Management",
      "Sustainable Building",
      "Civil Engineering",
      "Residential Construction",
      "Commercial Construction"
    ]
  },
  {
    name: "Creative",
    subtopics: ["Arts", "Music", "Design", "Broadcasting", "Film", "Theatre", "Graphic Design", "Fashion Design", "Media", "Entertainment", "Advertising", "Fashion", "Digitial Content", "Creative Technology"]
  },
  {
    name: "Ecology",
    subtopics: [
      "Environmental Science",
      "Energy Utilities",
      "Energy Resources",
      "Water",
      "Sustainability",
      "Planning",
      "Pollution",
      "Conservation",
      "Biodiversity",
      "Environmental Sustainability",
      "Ecosystem",
      "Wildlife Preservation",
      "Climate Change",
      "Renewable Resources",
      "Environmental Policy",
      "Ecological Restoration",
      "Wildlife",
      "Habitat Preservation",
      "Ecological Research",
      "Natural Resources"
    ]
  },
  {
    name: "Economics",
    subtopics: [
      "Business",
      "Commerce",
      "Management",
      "Wholesale",
      "Retail",
      "Retail Trade",
      "Trade",
      "Development",
      "Administration",
      "Telecommunication",
      "Entrepreneurship",
      "Real Estate",
      "Communication",
      "Marketing",
      "Quality Control",
      "Distribution",
      "Labour",
      "Publishing",
      "Logistics",
      "Macroeconomics",
      "Microeconomics",
      "Fiscal Policy",
      "Monetary Policy",
      "Economic Growth",
      "Supply and Demand",
      "Market Analysis",
      "Trade Economics",
      "Economic Theory",
      "Labor Market"
    ]
  },
  {
    name: "Education",
    subtopics: [
      "Universities",
      "Research",
      "Students",
      "Schools",
      "Networking",
      "International Studies",
      "Youth",
      "Methods of Instruction",
      "Training",
      "Physical Education",
      "Academies",
      "Educational Technology (Edtech)",
      "Curriculum",
      "Pedagogy",
      "Online Learning",
      "Teaching Methods",
      "Student Engagement",
      "Lifelong Learning",
      "Educational Institution",
      "Education Policy",
    ]
  },
  {
    name: "Energy",
    subtopics: [
      "Renewable Energy",
      "Cleantech ",
      "Renewable",
      "Green Energy",
      "Oil ",
      "Gas",
      "Mineral Resources",
      "Fossil Fuels",
      "Solar Power",
      "Wind Energy",
      "Energy Efficiency",
      "Electric Grid",
      "Energy Conservation",
      "Nuclear Energy",
      "Energy Production",
      "Sustainable Energy"
    ]
  },
  {
    name: "Geography",
    subtopics: ["Geology", "Geomorphology", "Oceanography", "Meteorology",
      "Cartography",
      "Geographic Information Systems (GIS)",
      "Topography",
      "Urban Planning",
      "Geospatial Analysis",
      "Environmental Geography",
      "Geographic Data",
      "Land Use",
      "Geographical Research",
      "Geophysics"
    ]
  },
  {
    name: "History",
    subtopics: ["Archeology",
      "Historiography",
      "Archives",
      "Historical Research",
      "Museum",
      "Heritage Preservation",
      "Oral History",
      "History Education",
      "Historiographic Methods",
      "Primary Sources"
    ]
  },
  {
    name: "Manufacturing and Industry",
    subtopics: [
      "Chemicals",
      "Electronics",
      "Industrial Machinery",
      "Metals",
      "Minerals",
      "Materials",
      "Mining",
      "Petrochemical",
      "Service",
      "Textile",
      "Packaging",
      "Plastics",
      "Wood",
      "High-Tech Manufacturing",
      "Lean Manufacturing",
      "Automotive",
      "Machinery ",
      "Production",
      "Industrial Automation",
      "Supply Chain",
      "Factory Operations",
      "Manufacturing Processes",
      "Industrial Engineering",
      "Mass Production",
      "Quality Control",
      "Industry 4.0"
    ]
  },
  {
    name: "Law",
    subtopics: [
      "International",
      "Standardization",
      "Private",
      "Forensic Science",
      "Social Matters",
      "Social Security",
      "Lawyers",
      "Criminology",
      "Commercial",
      "Patents",
      "IP",
      "Legal Services",
      "Litigation",
      "Jurisprudence",
      "Legal Counsel",
      "Legal Practice",
      "Legislation",
      "Legal Research",
      "Legal Compliance",
      "Judicial System",
      "Legal Ethics"
    ]
  },
  // {
  //   name: "Library, Literature & Information",
  //   subtopics: [
  //     "Documentation",
  //     "Multimedia",
  //     "Archives",
  //     "Writers",
  //     "Journalism"
  //   ]
  // },
  {
    name: "Linguistics",
    subtopics: ["Languages", "Translation",
      "Language Acquisition",
      "Phonetics",
      "Semantics",
      "Sociolinguistics",
      "Computational Linguistics",
      "Translation",
      "Language Teaching",
      "Bilingualism",
      "Morphology",
      "Language Analysis",
    ]
  },
  {
    name: "Mathematics & Statistics",
    subtopics: ["Actuary",
      "Mathematical Modeling",
      "Data Analysis",
      "Probability",
      "Statistical Analysis",
      "Quantitative Research",
      "Mathematical Research",
      "Numerical Methods",
      "Actuarial Science",
      "Applied Statistics",
      "Mathematical Software"
    ]
  },
  {
    name: "Pharma, Healthcare & Medicine",
    subtopics: [
      "Medical Science",
      "Neurology",
      "Alternative Medicine",
      "Clinical Chemistry",
      "Metabolism",
      "Hospitals",
      "Endoscopy",
      "Neonatal Health",
      "Otorhinolaryngology",
      "Rheumatology",
      "Sexology",
      "Forensic Medicine",
      "Gastroenterology",
      "Anatomy",
      "Immunology",
      "Emergency & Disaster Medicine",
      "Pediatrics",
      "Pharmacology",
      "Dentistry",
      "Public Health",
      "Veterinary Medicine",
      "Cancer",
      "Dermatology",
      "Surgery",
      "Mental Health",
      "Radiology",
      "Ophthalmology",
      "Endocrinology",
      "Gynaecology",
      "Oncology",
      "Nursing",
      "Anaesthesiology",
      "Genetics",
      "Biomedical Engineering",
      "Nutrition",
      "Brain",
      "Orthopaedics",
      "Cardiovascular Diseases",
      "Plastic & Reconstructive Surgery",
      "Drugs",
      "Infectious Diseases",
      "Toxicology",
      "Diabetes",
      "Physiology",
      "Epidemiology",
      "Transplantation",
      "Pathology",
      "Urology",
      "Cardiology",
      "Hematology",
      "Pharmacy",
      "Disease",
      "Life Science",
      "Biotechnology",
      "Medical Technology (Medtech)",
      "Medical Research",
      "Digital Health",
      "Telemedicine",
      "Pharmaceuticals",
      "Healthcare Services",
      "Hospital Management",
      "Clinical Trials",
      "Healthcare Innovation",
      "Medical Devices",
      "Healthcare Policy"
    ]
  },
  {
    name: "Safety & Security",
    subtopics: ["Police", "Data Protection", "Military", "Fire", "Geopolitics",
      "Security Services",
      "Safety Measures",
      "Surveillance",
      "Emergency Response",
      "Cybersecurity",
      "Risk Management",
      "Law Enforcement",
      "Public Safety",
      "Security Technology",
      "Disaster Preparedness"
    ],
  },
  {
    name: "Science",
    subtopics: [
      "Astronomy",
      "Space",
      "Electricity",
      "Mechanics",
      "Physics",
      "Meteorology",
      "Climatology",
      "Macromolecular",
      "Food",
      "Aerospace",
      "Geodesy",
      "Photogrammetry",
      "Remote Sensing",
      "Paper & Pulp",
      "Crystallization",
      "Scientific Research",
      "Laboratory Equipment",
      "Research Funding",
      "Scientific Publications",
      "Data Analysis",
      "Experimental Studies",
      "Scientific Collaboration",
      "STEM Education"
    ]
  },
  {
    name: "Social Science",
    subtopics: [
      "Sociology",
      "Psychology",
      "Philosophy",
      "Politics",
      "Human Rights",
      "Welfare",
      "Anthropology",
      "Behavioural Science",
      "Psychotherapy",
      "Social Research",
      "Political Science",
      "Social Policy",
      "Behavioral Studies",
      "Social Surveys",
      "Social Sciences Education"
    ]
  },
  {
    name: "Sports, Leisure & Culture",
    subtopics: [
      "Tourism",
      "Religion",
      "Travel",
      "Food & Beverage",
      "Ethics",
      "Ball Games",
      "Scouting",
      "Social Morality",
      "Cultural Heritage",
      "Recreation",
      "vitality",
      "Sports Facilities",
      "Sport"

    ]
  },
  {
    name: "Technology",
    subtopics: [
      "Computer Science",
      "Scientific Computing",
      "Engineering",
      "Digital",
      "Automation",
      "Robotics",
      "Artificial Intelligence",
      "Programming",
      "Nano",
      "Data Processing",
      "Simulation",
      "Informatica",
      "Innovation",
      "Research and Development",
      "High-Tech Manufacturing",
      "Technology Transfer",
      "Advanced Materials",
      "Clean Technologies",
      "Emerging Technologies",
      "Nanotechnology",
      "Technology Startups",
    ]
  },
  {
    name: "Transport",
    subtopics: [
      "Air",
      "Transport Equipment",
      "Automobiles",
      "Airports",
      "Public Transport",
      "Road",
      "Logistics",
      "Transportation Infrastructure",
      "Mobility Services",
      "Vehicle Manufacturing",
      "Freight Transport",
      "Urban Mobility",
      "Aviation",
      "Maritime Transport",
      "Rail Transport"
    ]
  },
  {
    name: "Shipping and Maritime",
    subtopics: [
      "Maritime Trade",
      "Port Operations",
      "Shipbuilding",
      "Shipping Logistics",
      "Maritime Safety",
      "Naval Architecture",
      "Cargo Shipping",
      "Maritime Law",
      "Marine Engineering",
      "International Shipping",

    ]
  },
  {
    name: "Finance & Banking",
    subtopics: [
      "Blockchain",
      "Fintech",
      "Crypto",
      "Risk Management",
      "Compliance",
      "Asset Management",
      "Payment Solutions",
      "Banking Services",
      "Financial Markets",
      "Banking Regulations",
      "Financial Institutions",
      "Credit and Lending",
      "Financial Planning",
      "Finance",
      "Accountancy",
      "Insurance",
      "Banking",
      "Auditors",
      "Tax",
      "Investment"
    ]
  },
  {
    name: "Information and Communication Technology",
    subtopics: [
      "Data Analytics",
      "Cybersecurity",
      "Cloud Computing",
      "Machine Learning",
      "Information Technology",
      "Telecommunications",
      "Hardware Technology",
      "Data Analytics",
      "Internet of Things (IoT)",
      "Network Infrastructure",
      "ICT Services",
      "ICT",  
      "Software", 
      "Internet",  
      "Networks", 
      "Information Systems"
    ]
  },
];
