<template>
  <v-dialog v-model="topicSubTopicDialog" :key="item._id" max-width="600" scrollable>
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" icon color="grey lighten-1">
        <v-icon
          :color="(item.sustainabilityCommitment) ? 'blue' : 'grey'">mdi-leaf
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="px-2 px-sm-6">
        <v-row class="pb-3">
          <v-col cols="8" lg="10" class="d-flex flex-column justify-center align-start py-0">
            <h3 class="title text-start text-break">
              Update GDS Profile:
              <span class="text-capitalize font-weight-bold">{{
    item.city
  }}</span>
            </h3>
          </v-col>
          <v-col cols="4" lg="2" class="d-flex flex-column justify-center align-end py-0">
            <v-btn icon color="grey lighten-1" @click="topicSubTopicDialog = false">
              <v-icon class="dialog-close-icon">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="9" class="py-2">
            <h3 class="text-left mt-4 mb-4">
              Is there an active Sustainability Commitment?
            </h3>

          </v-col>
          <v-col cols="12" md="1" class="py-6">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <font-awesome-icon style="margin-left: 10px;" class="info-icon" :icon="['fas', 'info-circle']"
                  color="secondary" size="L" v-on="on" />
              </template>
              <div style="max-width: 25vw">
                <span class="info-tooltip-text"> By clicking this button, you have to prove your
                  destination’s sustainability commitment by answering the following questions.</span>
              </div>
            </v-tooltip>
          </v-col>
          <v-col cols="12" md="2" class="py-1">
            <v-switch v-model="isToggleEnabled" class="my-4"></v-switch>

          </v-col>
        </v-row>
        <v-row class=" mt-3 ">
          <v-col cols="12" md="9" class="py-0">
            <h3 class="text-left mt-2 mb-4">
              Does your destination hold any sustainability certificates?
            </h3>
          </v-col>
          <v-col cols="12" md="1" class="py-0">
          </v-col>
          <v-col cols="12" md="2" class="py-0">
            <!-- Big Toggle Switch -->
            <v-switch v-model="isToggleEnableCertificate" :disabled="!isToggleEnabled"></v-switch>
          </v-col>
          <v-col cols="12" md="12" class="py-0">
            <v-file-input label="Upload Sustainability Certificate" placeholder="Choose File" prepend-icon
              append-icon="mdi-paperclip" clear-icon="mdi-close" v-model="file" @change="fileChanged"
              ref="fileClearUpload" :key="fileUploadKey" truncate-length="14" clearable show-size outlined required
              accept="application/pdf" hide-details="auto" :disabled="!isToggleEnableCertificate"></v-file-input>
          </v-col>
        </v-row>
        <v-row style="margin-top: 50px">
          <v-col cols="12" md="6" class="py-0">
            <v-btn type="submit" class="px-12 mb-3 mx-n0" color="secondary" tile depressed bottom
              :disabled="files.length < 1" @click="uploadDocs" :loading="uploadInProgress">
              Upload
            </v-btn>
          </v-col>
          <v-col cols="12" md="12" class="py-0" style="margin-top: 5px">
            <div v-for="f in documentUrls" :key="f">
              <h6 style="font-size: 12px">{{ decodeURIComponent(f.split('/')[f.split('/').length - 1]).replace(/[%()]/g,
    "") }}
                <button @click="removeFile(f, 2)">
                  <v-icon color="red">mdi-delete</v-icon>
                </button>
              </h6>
            </div>
            <p style="font-size: xx-small; font-weight: 700">Once you upload or delete a certificate,
               simply refresh the page to synchronize with the database. 
               This ensures that any changes made are reflected accurately and allows for a seamless experience.)</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12" class="py-0">
            <!-- Score -->
            <h3 class="text-left mt-4 mb-4">
              Sustainability Index Score (GDS)
            </h3>
            <v-text-field v-model="GDS_Index_Score" label="Score of Sust. Index" placeholder="Score" outlined
              :disabled="!isToggleEnabled"></v-text-field>
          </v-col>
        </v-row>
        <!-- Link -->
        <v-row>
          <v-col cols="12" md="12" class="py-0">
            <h3 class="text-left mt-4 mb-4">
              GDS Profile Link
            </h3>
            <v-text-field v-model="profile" label="GDS Profile Link" placeholder="Link" outlined
              :disabled="!isToggleEnabled"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12" class="py-0">
            <h3 class="text-left mt-4 mb-4">
              Overall Ranking
            </h3>
            <v-text-field class="my-0" v-model="Overall_Ranking" label="Score of overall Ranking" outlined
              :disabled="!isToggleEnabled"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12" class="py-0">
            <h3 class="text-left mt-4 mb-4">
              Overall SDG Index Score
            </h3>
            <v-text-field class="my-0" v-model="Overall_SDG_Index_Score" label="Score of SDG Index" outlined
              :disabled="!isToggleEnabled"></v-text-field>
          </v-col>

        </v-row>
        <h3 class="text-left mt-4 mb-4">
          Achieved SDGs
        </h3>
        <v-container>
          <v-row>
            <v-col>
              <v-row>

                <v-col cols="8"><span class="w-100">01. No Poverty</span></v-col>
                <v-col style="margin-top:-20px;">
                  <v-checkbox v-model="a1" primary :disabled="!isToggleEnabled"></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="ml-5">
              <v-row>

                <v-col cols="8"><span class="w-100">02. Zero Hunger</span></v-col>
                <v-col style="margin-top:-20px;">
                  <v-checkbox v-model="a2" primary :disabled="!isToggleEnabled"></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-row>

                <v-col cols="8"><span class="w-100">03. Good Health & Well-being</span></v-col>
                <v-col style="margin-top:-20px;">
                  <v-checkbox v-model="a3" primary :disabled="!isToggleEnabled"></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="ml-5">
              <v-row>

                <v-col cols="8"><span class="w-100">04. Quality Education</span></v-col>
                <v-col style="margin-top:-20px;">
                  <v-checkbox v-model="a4" primary :disabled="!isToggleEnabled"></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-row>

                <v-col cols="8"><span class="w-100">05. Gender Equality</span></v-col>
                <v-col style="margin-top:-20px;">
                  <v-checkbox v-model="a5" primary :disabled="!isToggleEnabled"></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="ml-5">
              <v-row>

                <v-col cols="8"><span class="w-100">06. Clean Water & Sanitation</span></v-col>
                <v-col style="margin-top:-20px;">
                  <v-checkbox v-model="a6" primary :disabled="!isToggleEnabled"></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-row>

                <v-col cols="8"><span class="w-100">07. Affordable & Clean Energy</span></v-col>
                <v-col style="margin-top:-20px;">
                  <v-checkbox v-model="a7" primary :disabled="!isToggleEnabled"></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="ml-5">
              <v-row>

                <v-col cols="8"><span class="w-100">08. Decent Work & Economic Growth</span></v-col>
                <v-col style="margin-top:-20px;">
                  <v-checkbox v-model="a8" primary :disabled="!isToggleEnabled"></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-row>

                <v-col cols="8"><span class="w-100">09. Industry, Innovation & Infrastructure</span></v-col>
                <v-col style="margin-top:-20px;">
                  <v-checkbox v-model="a9" primary :disabled="!isToggleEnabled"></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="ml-5">
              <v-row>

                <v-col cols="8"><span class="w-100">10. Reduced Inequalities</span></v-col>
                <v-col style="margin-top:-20px;">
                  <v-checkbox v-model="a10" primary :disabled="!isToggleEnabled"></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-row>

                <v-col cols="8"><span class="w-100">11. Sustainable Cities & Communities</span></v-col>
                <v-col style="margin-top:-20px;">
                  <v-checkbox v-model="a11" primary :disabled="!isToggleEnabled"></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="ml-5">
              <v-row>

                <v-col cols="8"><span class="w-100">12. Responsible Consumption & Production</span></v-col>
                <v-col style="margin-top:-20px;">
                  <v-checkbox v-model="a12" primary :disabled="!isToggleEnabled"></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-row>

                <v-col cols="8"><span class="w-100">13. Climate Action</span></v-col>
                <v-col style="margin-top:-20px;">
                  <v-checkbox v-model="a13" primary :disabled="!isToggleEnabled"></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="ml-5">
              <v-row>

                <v-col cols="8"><span class="w-100">14. Life Below Water</span></v-col>
                <v-col style="margin-top:-20px;">
                  <v-checkbox v-model="a14" primary :disabled="!isToggleEnabled"></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-row>

                <v-col cols="8"><span class="w-100">15. Life on Land</span></v-col>
                <v-col style="margin-top:-20px;">
                  <v-checkbox v-model="a15" primary :disabled="!isToggleEnabled"></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="ml-5">
              <v-row>
                <v-col cols="8"><span class="w-100">16. Peace, Justice & Strong Institutions</span></v-col>
                <v-col style="margin-top:-20px;">
                  <v-checkbox v-model="a16" primary :disabled="!isToggleEnabled"></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-row>
                <v-col cols="8"><span class="w-100">17. Partnerships for the Goals</span></v-col>
                <v-col style="margin-top:-20px;">
                  <v-checkbox v-model="a17" primary :disabled="!isToggleEnabled"></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="ml-5">
              <v-row>
                <!-- <v-col><img style="width:30px;height:auto;" src="https://conferli-storage.s3.us-east-2.amazonaws.com/sdg/inverted/E+Inverted+Icons_WEB-16.png"></v-col>
                <v-col cols="8"><span class="w-100">16. Peace, Justice & Strong Institutions</span></v-col>
                <v-col style="margin-top:-20px;"><v-checkbox v-model="a1" primary></v-checkbox ></v-col> -->
              </v-row>
            </v-col>
          </v-row>
        </v-container>
        <h3 class="text-left mt-4 mb-4">
          Focused SDGs
          <v-text style="font-size:12px; "> (Maximum 5)</v-text>
        </h3>
        <v-container>
          <v-row>
            <v-col>
              <v-row>

                <v-col cols="8"><span class="w-100">01. No Poverty</span></v-col>
                <v-col style="margin-top:-20px;">
                  <v-checkbox v-model="s1" primary :disabled="!isToggleEnabled"></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="ml-5">
              <v-row>

                <v-col cols="8"><span class="w-100">02. Zero Hunger</span></v-col>
                <v-col style="margin-top:-20px;">
                  <v-checkbox v-model="s2" primary :disabled="!isToggleEnabled"></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-row>

                <v-col cols="8"><span class="w-100">03. Good Health & Well-being</span></v-col>
                <v-col style="margin-top:-20px;">
                  <v-checkbox v-model="s3" primary :disabled="!isToggleEnabled"></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="ml-5">
              <v-row>

                <v-col cols="8"><span class="w-100">04. Quality Education</span></v-col>
                <v-col style="margin-top:-20px;">
                  <v-checkbox v-model="s4" primary :disabled="!isToggleEnabled"></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-row>

                <v-col cols="8"><span class="w-100">05. Gender Equality</span></v-col>
                <v-col style="margin-top:-20px;">
                  <v-checkbox v-model="s5" primary :disabled="!isToggleEnabled"></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="ml-5">
              <v-row>

                <v-col cols="8"><span class="w-100">06. Clean Water & Sanitation</span></v-col>
                <v-col style="margin-top:-20px;">
                  <v-checkbox v-model="s6" primary :disabled="!isToggleEnabled"></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-row>

                <v-col cols="8"><span class="w-100">07. Affordable & Clean Energy</span></v-col>
                <v-col style="margin-top:-20px;">
                  <v-checkbox v-model="s7" primary :disabled="!isToggleEnabled"></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="ml-5">
              <v-row>

                <v-col cols="8"><span class="w-100">08. Decent Work & Economic Growth</span></v-col>
                <v-col style="margin-top:-20px;">
                  <v-checkbox v-model="s8" primary :disabled="!isToggleEnabled"></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-row>

                <v-col cols="8"><span class="w-100">09. Industry, Innovation & Infrastruture</span></v-col>
                <v-col style="margin-top:-20px;">
                  <v-checkbox v-model="s9" primary :disabled="!isToggleEnabled"></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="ml-5">
              <v-row>

                <v-col cols="8"><span class="w-100">10. Reduced Inequalities</span></v-col>
                <v-col style="margin-top:-20px;">
                  <v-checkbox v-model="s10" primary :disabled="!isToggleEnabled"></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-row>

                <v-col cols="8"><span class="w-100">11. Sustainable Cities & Communities</span></v-col>
                <v-col style="margin-top:-20px;">
                  <v-checkbox v-model="s11" primary :disabled="!isToggleEnabled"></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="ml-5">
              <v-row>

                <v-col cols="8"><span class="w-100">12. Responsible Consumption & Production</span></v-col>
                <v-col style="margin-top:-20px;">
                  <v-checkbox v-model="s12" primary :disabled="!isToggleEnabled"></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-row>

                <v-col cols="8"><span class="w-100">13. Climate Action</span></v-col>
                <v-col style="margin-top:-20px;">
                  <v-checkbox v-model="s13" primary :disabled="!isToggleEnabled"></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="ml-5">
              <v-row>

                <v-col cols="8"><span class="w-100">14. Life Below Water</span></v-col>
                <v-col style="margin-top:-20px;">
                  <v-checkbox v-model="s14" primary :disabled="!isToggleEnabled"></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-row>

                <v-col cols="8"><span class="w-100">15. Life on Land</span></v-col>
                <v-col style="margin-top:-20px;">
                  <v-checkbox v-model="s15" primary :disabled="!isToggleEnabled"></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="ml-5">
              <v-row>
                <v-col cols="8"><span class="w-100">16. Peace, Justice & Strong Institutions</span></v-col>
                <v-col style="margin-top:-20px;">
                  <v-checkbox v-model="s16" primary :disabled="!isToggleEnabled"></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-row>
                <v-col cols="8"><span class="w-100">17. Partnerships for the Goals</span></v-col>
                <v-col style="margin-top:-20px;">
                  <v-checkbox v-model="s17" primary :disabled="!isToggleEnabled"></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="ml-5">
              <v-row>
                <!-- <v-col><img style="width:30px;height:auto;" src="https://conferli-storage.s3.us-east-2.amazonaws.com/sdg/inverted/E+Inverted+Icons_WEB-16.png"></v-col>
                <v-col cols="8"><span class="w-100">16. Peace, Justice & Strong Institutions</span></v-col>
                <v-col style="margin-top:-20px;"><v-checkbox v-model="a1" primary></v-checkbox ></v-col> -->
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="9" class="py-0">
              <h3 class="text-left mt-4 mb-4">
                Are there venues in your destination that hold a Green Key?
              </h3>
            </v-col>
            <v-col cols="12" md="1" class="py-0">
            </v-col>
            <v-col cols="12" md="2" class="py-0">
              <v-switch v-model="isTextFieldEnabled" :disabled="!isToggleEnabled"></v-switch>
            </v-col>
            <v-text-field class="my-0" v-model="noVenueGreenKey" :disabled="!isTextFieldEnabled" label="Number of venues that hold a green key"
              outlined></v-text-field>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="px-6">
        <v-row class="
            d-flex
            flex-column-reverse flex-sm-row
            align-center
            justify-space-between
            mx-0
          ">
          <v-col cols="12" sm="4" class="d-flex flex-column align-center align-sm-start px-0">
            <v-btn class="px-8 mx-0" color="secondary" @click="topicSubTopicDialog = false" tile outlined depressed
              bottom>Cancel
            </v-btn>
          </v-col>
          <v-col cols="12" sm="4" class="d-flex flex-column align-center align-sm-end px-0">
            <v-btn class="px-8 mx-0" @click="submitFn" :loading="saving" :disabled="saving" color="secondary" tile
              depressed bottom right>Submit
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import cityApicalls from "../../../../services/city.service";

export default {
  name: "Topics",
  props: {
    item: Object,
  },
  data() {
    return {
      loading: true,
      topicSubTopicDialog: false,
      profile: undefined,
      noVenueGreenKey: undefined,
      isTextFieldEnabled: false,
      isToggleEnabled: false,
      isToggleEnableCertificate: false,
      isDocumentUploaderEnabled: false,
      file: null,
      files: [],
      uploadInProgress: false,
      fileUploadKey: Number(new Date()),
      Overall_Ranking: undefined,
      Overall_SDG_Index_Score: undefined,
      GDS_Index_Score: undefined,
      cityId: undefined,
      saving: false,
      a1: false,
      a2: false,
      a3: false,
      a4: false,
      a5: false,
      a6: false,
      a7: false,
      a8: false,
      a9: false,
      a10: false,
      a11: false,
      a12: false,
      a13: false,
      a14: false,
      a15: false,
      a16: false,
      a17: false,
      s1: false,
      s2: false,
      s3: false,
      s4: false,
      s5: false,
      s6: false,
      s7: false,
      s8: false,
      s9: false,
      s10: false,
      s11: false,
      s12: false,
      s13: false,
      s14: false,
      s15: false,
      s16: false,
      s17: false,
      city:null,
      documentUrls:[]
    };
  },
  watch: {
    topicSubTopicDialog(val) {
      if (val) {
        this.initFn();
      }
    },
    isToggleEnabled(newValue) {
      localStorage.setItem('isToggleEnabled', newValue);
      if (!newValue) {
        this.a1 = false;
        this.a2 = false;
        this.a3 = false;
        this.a4 = false;
        this.a5 = false;
        this.a6 = false;
        this.a7 = false;
        this.a8 = false;
        this.a9 = false;
        this.a10 = false;
        this.a11 = false;
        this.a12 = false;
        this.a13 = false;
        this.a14 = false;
        this.a15 = false;
        this.a16 = false;
        this.a17 = false;
        this.s1 = false;
        this.s2 = false;
        this.s3 = false;
        this.s4 = false;
        this.s5 = false;
        this.s6 = false;
        this.s7 = false;
        this.s8 = false;
        this.s9 = false;
        this.s10 = false;
        this.s11 = false;
        this.s12 = false;
        this.s13 = false;
        this.s14 = false;
        this.s15 = false;
        this.s16 = false;
        this.s17 = false;

        this.file = null;
        this.Overall_Ranking = null;
        this.profile = null;
        this.Overall_SDG_Index_Score = null;
        this.GDS_Index_Score = null;
        this.noVenueGreenKey = null;
        this.isTextFieldEnabled = null;
        this.isToggleEnableCertificate = null;

      }
    },
    isTextFieldEnabled(newValue) {
      localStorage.setItem('isTextFieldEnabled', newValue);
      if (!newValue) {
        this.noVenueGreenKey = null;
      }
    },
    isToggleEnableCertificate(newValue) {
      localStorage.setItem('isToggleEnableCertificate', newValue);
      if (!newValue) {
        this.file = null;
      }
    },
  },
  mounted() {
    if (localStorage.getItem('isTextFieldEnabled') !== null) {
      this.isTextFieldEnabled = JSON.parse(localStorage.getItem('isTextFieldEnabled'));
    }
    if (localStorage.getItem('isToggleEnableCertificate') !== null) {
      this.isToggleEnableCertificate = JSON.parse(localStorage.getItem('isToggleEnableCertificate'));
    }

  },
  methods: {
    fileChanged(file) {
      if (file) {
        this.files.push(file);
      }
      this.file = null;
    },
    async removeFile(file, stage) {
      if (stage === 1) {
        for (let i = 0; i < this.files.length; i++) {
          if (file === this.files[i].name) {
            this.files.splice(this.files.indexOf(this.files[i]), 1);
            break
          }
        }
      } else {
        for (let i = 0; i < this.item.sustainabilityCertificates.length; i++) {
          if (file === this.item.sustainabilityCertificates[i]) {
            const payload = {
              profile:this.item.user,
              url:this.item.sustainabilityCertificates[i]
            }
            this.item.sustainabilityCertificates.splice(this.item.sustainabilityCertificates.indexOf(this.item.sustainabilityCertificates[i]), 1);
            cityApicalls.deleteSustainabilityCertificate(payload);
            this.initFn();
            break
          }
        }
      }
    },
    uploadDocs(e) {
      e.preventDefault();
      this.uploadInProgress = true;
      var formData = new FormData();
      for (let i = 0; i < this.files.length; i++) {
        formData.append(`file_${i}`, this.files[i]);
      }
      formData.append("profile",this.item.user);
      cityApicalls.uploadSustainabilityCertificate(formData).then((res) => {
        this.documentUrls = [];
        for (let i = 0; i < res.data.content.length; i++) {
          try {
            this.documentUrls.push(res.data.content[i]);
          } catch (e) {
            this.documentUrls = [];
            this.documentUrls.push(res.data.content[i]);
          }
        }
        let payload = {
          snackBarText: "Please refresh your profile to collect updated data.",
          snackBarColor: "success"
        };
        this.$store.dispatch("setSnackbarMessage", payload);
        this.uploadInProgress = false;
        this.file = null;
        this.files = [];
        this.$emit('documentsUploaded', this.documentUrls);
        this.$emit("initFN");
        this.supportingDocEditDialog = false;
      });
    },
    async initFn() {
      if (this.item) {
        this.isToggleEnabled = this.item.sustainabilityCommitment;
        this.isToggleEnabled = this.item.isToggleEnabled;
        this.isToggleEnableCertificate = this.item.isToggleEnableCertificate;
        this.isTextFieldEnabled = this.item.isTextFieldEnabled;
        this.noVenueGreenKey = this.item.noVenueGreenKey;
        this.documentUrls = this.item.sustainabilityCertificates;
        this.Overall_Ranking = this.item.overallRanking;
        this.Overall_SDG_Index_Score = this.item.overallSDGScore;
        this.GDS_Index_Score = this.item.overallGDSScore;
        this.profile = this.item.gdsProfile;
        this.a1 = this.item.achievedSDGs.includes(1);
        this.a2 = this.item.achievedSDGs.includes(2);
        this.a3 = this.item.achievedSDGs.includes(3);
        this.a4 = this.item.achievedSDGs.includes(4);
        this.a5 = this.item.achievedSDGs.includes(5);
        this.a6 = this.item.achievedSDGs.includes(6);
        this.a7 = this.item.achievedSDGs.includes(7);
        this.a8 = this.item.achievedSDGs.includes(8);
        this.a9 = this.item.achievedSDGs.includes(9);
        this.a10 = this.item.achievedSDGs.includes(10);
        this.a11 = this.item.achievedSDGs.includes(11);
        this.a12 = this.item.achievedSDGs.includes(12);
        this.a13 = this.item.achievedSDGs.includes(13);
        this.a14 = this.item.achievedSDGs.includes(14);
        this.a15 = this.item.achievedSDGs.includes(15);
        this.a16 = this.item.achievedSDGs.includes(16);
        this.a17 = this.item.achievedSDGs.includes(17);

        this.s1 = this.item.focusSDG.includes(1);
        this.s2 = this.item.focusSDG.includes(2);
        this.s3 = this.item.focusSDG.includes(3);
        this.s4 = this.item.focusSDG.includes(4);
        this.s5 = this.item.focusSDG.includes(5);
        this.s6 = this.item.focusSDG.includes(6);
        this.s7 = this.item.focusSDG.includes(7);
        this.s8 = this.item.focusSDG.includes(8);
        this.s9 = this.item.focusSDG.includes(9);
        this.s10 = this.item.focusSDG.includes(10);
        this.s11 = this.item.focusSDG.includes(11);
        this.s12 = this.item.focusSDG.includes(12);
        this.s13 = this.item.focusSDG.includes(13);
        this.s14 = this.item.focusSDG.includes(14);
        this.s15 = this.item.focusSDG.includes(15);
        this.s16 = this.item.focusSDG.includes(16);
        this.s17 = this.item.focusSDG.includes(17);
      }
    },
    submitFn() {
      this.saving = true;
      let ach = this.getAchivedSDG();
      let fcs = this.getFCSSDG();
      if (fcs.length < 6) {
        let pld = {
          _id: this.item._id,
          overallRanking: this.Overall_Ranking,
          isToggleEnabled : this.isToggleEnabled,
          isToggleEnableCertificate : this.isToggleEnableCertificate,
          isTextFieldEnabled : this.isTextFieldEnabled,
          noVenueGreenKey: this.noVenueGreenKey,
          overallSDGScore: this.Overall_SDG_Index_Score,
          overallGDSScore: this.GDS_Index_Score,
          profile: this.profile,
          achievedSDGs: ach,
          focusSDG: fcs,
        }
        cityApicalls.setSustainabilityInitiatives(pld).then((res) => {
          console.log(res)
          let payload2 = {
            snackBarText: `The city '${this.item.city}' has been Updated successfully`,
            snackBarColor: "secondary",
          };
          this.$store.dispatch("notify/setSnackbarMessage", payload2);
          this.saving = false;
          this.topicSubTopicDialog = false;
          this.$emit("initFN");
        });

      } else {
        let payload = {
          snackBarText: "You can select maximum 5 SDGs for SDGs to Focus.",
          snackBarColor: "warning",
        };
        this.$store.dispatch("notify/setSnackbarMessage", payload);
        this.saving = false;
      }
    },
    getAchivedSDG() {
      let selected = [];
      if (this.a1) {
        selected.push(1);
      }
      if (this.a2) {
        selected.push(2);
      }
      if (this.a3) {
        selected.push(3);
      }
      if (this.a4) {
        selected.push(4);
      }
      if (this.a5) {
        selected.push(5);
      }
      if (this.a6) {
        selected.push(6);
      }
      if (this.a7) {
        selected.push(7);
      }
      if (this.a8) {
        selected.push(8);
      }
      if (this.a9) {
        selected.push(9);
      }
      if (this.a10) {
        selected.push(10);
      }
      if (this.a11) {
        selected.push(11);
      }
      if (this.a12) {
        selected.push(12);
      }
      if (this.a13) {
        selected.push(13);
      }
      if (this.a14) {
        selected.push(14);
      }
      if (this.a15) {
        selected.push(15);
      }
      if (this.a16) {
        selected.push(16);
      }
      if (this.a17) {
        selected.push(17);
      }
      return selected;
    },
    getFCSSDG() {
      let selected = [];
      if (this.s1) {
        selected.push(1);
      }
      if (this.s2) {
        selected.push(2);
      }
      if (this.s3) {
        selected.push(3);
      }
      if (this.s4) {
        selected.push(4);
      }
      if (this.s5) {
        selected.push(5);
      }
      if (this.s6) {
        selected.push(6);
      }
      if (this.s7) {
        selected.push(7);
      }
      if (this.s8) {
        selected.push(8);
      }
      if (this.s9) {
        selected.push(9);
      }
      if (this.s10) {
        selected.push(10);
      }
      if (this.s11) {
        selected.push(11);
      }
      if (this.s12) {
        selected.push(12);
      }
      if (this.s13) {
        selected.push(13);
      }
      if (this.s14) {
        selected.push(14);
      }
      if (this.s15) {
        selected.push(15);
      }
      if (this.s16) {
        selected.push(16);
      }
      if (this.s17) {
        selected.push(17);
      }
      return selected;
    }
  },
};
</script>

<style scoped>
.img-placeholder {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.img-cover-placeholder {
  width: 100%;
  height: auto;
  max-height: 300px;
  border-radius: 5px;
}
</style>
<style>
.v-dialog .v-card .v-card__title .v-icon.dialog-close-icon {
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  width: 32px;
  height: 32px;
  font-size: 24px;
}
</style>
